import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Helmet from "react-helmet"

// import QuickLinks from "../../components/quickLinks"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ArrowWhite from "../../components/arrowWhite"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"

class About extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: "Fuse Classroom",
              image: "https://fuseclassroom.com/images/ogimage.jpg",
              description:
                "AI-enabled education platform with smart AI features that empower both school administrators, instructors, and students.",
              brand: "Fusemachines",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "3.6",
                bestRating: "5",
                worstRating: "1",
                ratingCount: "512",
              },
            })}
          </script>
        </Helmet>
        <SEO
          title={"About"}
          titleDesc={
            "Fuse Classroom is an AI-enabled education platform that provides online live classroom experiences and other AI features for schools and colleges."
          }
          titleKeywords={
            "fuse classroom, online classes, lms, fuse classroom app,online learning, online live classes, online classroom, ai enabled education platform, online education platform, online learning platform, e learning platforms, virtual teaching platforms"
          }
          fbDes={"Fuse Classroom is an AI-enabled platform that provides online live classroom experiences."}
          twitDesc={"Fuse Classroom is an AI-enabled platform that provides online live classroom experiences.  "}
          seoLink={"about/"}
        />

        {/* top section */}
        <section role="top--section" className="about">
          {/* content */}
          <article className="container h-100">
            <Row className="align-items-center justify-content-between h-100">
              <Col lg={{ span: 6, order: 1 }} className="text-white">
                <div className="my-5">
                  <h1 className="font-weight-bold text-white fs-60">Transforming Education</h1>
                  <div className="h4 mt-4 text-white font-weight-normal">
                    Fuse Classroom is an AI-enabled education platform brought to you by Fusemachines. Our core goal is
                    to transform the world with our mission to democratize Artificial Intelligence. To this aim, our
                    proprietary platform helps transform universities and colleges into online learning institutions.
                    The platform enables online-live classroom experiences, tutoring powered by AI, student progress
                    tracking, and more AI features for all besides.
                  </div>

                  <div className="main-cta mt-5">
                    <a
                      href="https://fusemachines.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="outer-link"
                      id="learn-more__about"
                    >
                      <ArrowWhite /> Learn more about Fusemachines
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 5, order: 2 }} className="mt-4 mt-lg-0">
                <img
                  src="/images/about/transform-education.webp"
                  className="img-fluid"
                  alt="Transforming Education"
                  width="500"
                  height="auto"
                />
              </Col>
            </Row>
          </article>
        </section>
        {/* End of top section */}

        {/* Company Description */}
        <section className="py-md-5" id="about-training">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="5" className="my-5">
                <div className="ab-mb">
                  <div className="text-lightgreen fs-60">240+</div>
                  <h2 className="fs-40">Global Employees</h2>
                </div>
                <div className="mt-3 ab-mb">
                  <div className="text-lightgreen fs-60">4</div>
                  <h2 className="fs-40">
                    Countries <br className="d-none d-md-block" />
                    We Have Offices
                  </h2>
                </div>
              </Col>
              <Col md="7">
                <img src="/images/about/map.svg" className="img-fluid" alt="Location Map" width="620" height="auto" />
              </Col>
            </Row>
          </article>
        </section>
        {/* End of Company Description */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in becoming partner?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("About_Bottom_Request_Demo")}
                    state={{ page: "aboutschool" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default About
